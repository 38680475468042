<template>
	<div class="project-list">
		<div class="project-container" v-for="(d, i) in records" :key="i">
			<div class="project">
				<div class="title">
					<router-link :to="'/project/' + d.id">{{ d.title }}</router-link>
				</div>
				<div class="project-time">
					{{ d.beginDate | dateFormat("YYYY-MM-DD") }}
				</div>
				<div class="location">
					<div class="entry">
						<div class="label label1">项目所在地：</div>
						<div class="value val">
							{{ d.country.name }}
						</div>
					</div>
					<div class="entry">
						<div class="label label1">项目负责人：</div>
						<div class="value value1">
							{{ d.leader }}
						</div>
					</div>
					<div class="entry">
						<div class="label">承担机构：</div>
						<div class="value value1">
							{{ d.leaderInstitution }}
						</div>
					</div>
					<div class="entry">
						<div class="label">经费金额（元）：</div>
						<div class="value">
							{{ d.budgets }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination">
			<el-pagination
				id="pagination"
				:current-page.sync="pager.index"
				:page-sizes="[10, 50, 100]"
				:page-size.sync="pager.size"
				layout="prev, pager, next, jumper,sizes"
				:total="pager.total"
				@current-change="query"
				@size-change="sizeChange"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../../api"

export default {
	data() {
		return {
			api: new UserCenterApi(ApiClient.instance),
			records: [],
			total: 0,
			pager: {
				index: 1,
				size: 10,
				total: 10
			}
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		query() {
			this.api.apiUserCenterFavoritesGet(
				4,
				{
					index: this.pager.index,
					size: this.pager.size
				},
				(e, data, resp) => {
					data = resp.body
					if (data.success) {
						this.records = data.result.records
						this.pager.total = data.result.total
					}
				}
			)
		},
		sizeChange(size) {
			this.pager.size = size
			this.pager.index = 1
			this.query()
		}
	}
}
</script>

<style lang="less" scoped>
.project-list {
	width: 100%;
}

.project-container {
	width: 100%;
	display: inline-block;
	background-color: #ffffff;
	margin-top: 16px;
}

.project {
	margin: 16px;
	position: relative;

	.title {
		.c-font-size-12();
		color: #3463ea;
		line-height: 20px;
		text-align: left;
		display: inline-block;
	}

	.project-time {
		display: inline-block;
		position: absolute;
		right: 23px;
		color: gray;
	}

	.location {
		.c-font-size-12();
		line-height: 20px;
		display: flex;
		flex-direction: row;
		text-align: left;
		overflow: hidden;
		.entry {
			display: flex;
			flex-direction: row;
			margin-right: 20px;
			.value1 {
				width: 192px;
				max-height: 40px;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2; //行数需设置
				line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			.val {
				width: 60px;
				max-height: 40px;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2; //行数需设置
				line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			.label {
				width: 60px;
			}
			.label1 {
				width: 72px !important;
			}
		}
	}

	.link {
		display: flex;
		flex-direction: row;
		.c-font-size-12();
		line-height: 20px;
	}
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
