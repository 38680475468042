var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-list" },
    [
      _vm._l(_vm.records, function (d, i) {
        return _c("div", { key: i, staticClass: "project-container" }, [
          _c("div", { staticClass: "project" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("router-link", { attrs: { to: "/project/" + d.id } }, [
                  _vm._v(_vm._s(d.title)),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "project-time" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("dateFormat")(d.beginDate, "YYYY-MM-DD")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "location" }, [
              _c("div", { staticClass: "entry" }, [
                _c("div", { staticClass: "label label1" }, [
                  _vm._v("项目所在地："),
                ]),
                _c("div", { staticClass: "value val" }, [
                  _vm._v(" " + _vm._s(d.country.name) + " "),
                ]),
              ]),
              _c("div", { staticClass: "entry" }, [
                _c("div", { staticClass: "label label1" }, [
                  _vm._v("项目负责人："),
                ]),
                _c("div", { staticClass: "value value1" }, [
                  _vm._v(" " + _vm._s(d.leader) + " "),
                ]),
              ]),
              _c("div", { staticClass: "entry" }, [
                _c("div", { staticClass: "label" }, [_vm._v("承担机构：")]),
                _c("div", { staticClass: "value value1" }, [
                  _vm._v(" " + _vm._s(d.leaderInstitution) + " "),
                ]),
              ]),
              _c("div", { staticClass: "entry" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("经费金额（元）："),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(d.budgets) + " "),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              id: "pagination",
              "current-page": _vm.pager.index,
              "page-sizes": [10, 50, 100],
              "page-size": _vm.pager.size,
              layout: "prev, pager, next, jumper,sizes",
              total: _vm.pager.total,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.pager, "index", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.pager, "index", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.pager, "size", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.pager, "size", $event)
              },
              "current-change": _vm.query,
              "size-change": _vm.sizeChange,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }